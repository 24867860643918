@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed&display=swap");

html {
  color: #fff;
  font-family: "Roboto Condensed", sans-serif;
  background-image: url(bg.jpg);
  background-position: top top;
  background-size: cover;
  margin: 0;
}

.App {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  background: rgb(106, 12, 172);
  background: linear-gradient(
    0deg,
    rgba(106, 12, 172, 1) 0%,
    rgba(106, 12, 172, 1) 50%,
    rgba(106, 12, 172, 0.6) 100%
  );
}

.container {
  width: 100% !important;
  margin: 10% auto;
}
.content {
  margin-top: 0;
  position: relative;
  top: -3em;
}
.col-4 {
  width: 25%;
  float: left;
}

h1 {
  font-size: 38px;
  text-transform: uppercase;
  line-height: 1;
}

@media (min-width: 768px) {
  .container {
    width: 1100px;
  }
  h1 {
    font-size: 58px;
  }
}
